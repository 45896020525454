import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const location = useLocation();
  const currentURL = `https://english-news.live/${location.pathname}`;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <link rel="canonical" href={currentURL} />
          <title>
            Live English News - Latest News Updates, Breaking News & Top
            Headlines
          </title>
          <meta
            name="description"
            content="Watch live English news from India Today, CNN News18, NDTV 24x7, and more. Stay updated with breaking news, top headlines, and real-time news updates in English."
          />
          <meta
            name="keywords"
            content="English news, live news, latest English news, breaking English news, today’s top headlines, top English news, live news channels, India Today Live, CNN News18 Live, NDTV Live, Republic TV Live, WION Live, today’s news updates, business news, financial news."
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            Welcome to www.english-news.live. This Privacy Policy explains how
            we collect, use, and safeguard your personal information when you
            access our website and services. By using our platform, you agree to
            the practices outlined in this policy.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information We Collect</h2>
              <p>We may collect two types of information from users:</p>
              <ol className="number">
                <li>
                  <h3>Personal Information</h3>: We do not collect personal data
                  such as names, email addresses, or phone numbers unless
                  voluntarily provided (e.g., through contact forms,
                  newsletters, or account creation).
                </li>
                <li>
                  <h3>Non-Personal Information</h3>: We gather non-identifiable
                  information such as IP addresses, browser types, pages
                  visited, and time spent on the site. This helps us enhance the
                  website’s performance and understand user interactions.
                </li>
              </ol>
            </li>
            <li>
              <h2>Cookies and Tracking Technologies</h2>
              <p>
                We use cookies and similar technologies to enhance your
                experience on our website. These tools help analyze traffic,
                customize content, and improve user experience. You can manage
                or disable cookies through your browser settings, though this
                may affect certain site features.
              </p>
            </li>
            <li>
              <h2> Use of Information</h2>
              <p>The information we collect is used to:</p>
              <ul className="dash-list">
                <li>Improve website performance and content delivery.</li>
                <li> Personalize user experience.</li>
                <li>Monitor and analyze usage trends and preferences.</li>
                <li>
                  Comply with legal obligations or protect the rights of
                  www.english-news.live.
                </li>
              </ul>
            </li>
            <li>
              <h2>Content Ownership and Intellectual Property</h2>
              <p>
                All news articles, videos, and images featured on
                www.english-news.live are owned by their respective creators. We
                do not claim ownership of any third-party content displayed on
                our platform. As a news aggregator, we provide content from
                various sources. If you believe any content violates copyright
                laws, please contact us for prompt action.
              </p>
            </li>
            <li>
              <h2>Third-Party Links</h2>
              <p>
                Our site may include links to external websites or services. We
                are not responsible for the privacy practices or content of
                these external sites. We recommend reviewing their privacy
                policies before providing personal information.
              </p>
            </li>
            <li>
              <h2>Data Security</h2>
              <p>
                We take reasonable steps to protect information collected
                through our website from unauthorized access, use, or
                disclosure. However, no method of online transmission is
                completely secure, and we cannot guarantee absolute data
                security.
              </p>
            </li>
            <li>
              <h2> Children's Privacy</h2>
              <p>
                Our website is not intended for use by children under the age of
                13. We do not knowingly collect personal information from
                children. If you are a parent or guardian and believe your child
                has shared personal information, please contact us, and we will
                promptly remove it.
              </p>
            </li>
            <li>
              <h2>Changes to This Policy</h2>
              <p>
                We may update this Privacy Policy periodically to reflect
                changes in our practices or legal requirements. Any updates will
                be posted on this page, and the revised policy will take effect
                immediately upon posting.
              </p>
            </li>
            <li>
              <h2>Contact Us</h2>
              <p>
                For questions or concerns regarding this Privacy Policy or the
                practices of www.english-news.live, please contact us at: Email:
                contact@english-news.live
              </p>
            </li>
          </ol>
          <p>
            We are a news aggregator and do not claim ownership over any
            third-party content. All content rights remain with the original
            owners.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
